import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { MRT_ColumnDef, MRT_GroupingState, MRT_Row, MRT_SortingState } from 'material-react-table';
import { LunchReceiptTenantDTO, MobilityReceiptTenantDTO, ReceiptDTOStatusEnum, RecreationReceiptTenantDTO } from 'probonio-shared-ui/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfiniteScrollReactTable } from '../../../component/table/InfiniteScrollReactTable';
import { mapSortModel, SortByDef } from '../../../component/table/sortMapper';
import { useLoadMore } from '../../../component/table/useLoadMore';
import { ReportFilterSelector, useReportFilterState } from '../report';
import { useExportReceiptsMutation, useReceiptsQuery } from './useReceiptsQuery';
import { getValidityMonths, mapReceiptStatus } from './useReceiptStatus';

interface Props {
  benefit: 'LUNCH' | 'MOBILITY' | 'RECREATION';
  selectedReceipt?: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO;
  onSelectReceipt: (receipt: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO) => void;
  onReceiptUpdated: (receipt: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO) => void;
}

export const ReceiptList: React.FC<Props> = ({ benefit, selectedReceipt, onSelectReceipt, onReceiptUpdated }) => {
  const { t } = useTranslation('benefitsModule');
  const reportFilterState = useReportFilterState();
  const [sorting, setSorting] = useState<MRT_SortingState>([{ id: 'name', desc: false }]);

  const COLUMNS: (MRT_ColumnDef<LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO> & SortByDef)[] = useMemo(
    () => [
      {
        id: 'name',
        accessorFn: receipt => {
          const user = receipt.employee.user;
          if (!user) {
            return '';
          }
          if (user.firstName || user.lastName) {
            return `${user.lastName}${user.firstName && user.lastName && ', '}${user.firstName}`;
          }
          return user.mail || '';
        },

        sortBy: ['employee.user.lastName', 'employee.user.firstName', 'employee.user.mail', 'date'],
        header: t('receipts.from'),
        enableGrouping: false,
      },
      {
        accessorKey: 'date',
        header: t('receipts.date'),
        enableGrouping: false,
        sortBy: ['date'],
        Cell: ({ cell }) => (cell.renderValue() ? t('common:date', { date: new Date(cell.renderValue() as string) }) : null),
      },
      {
        id: 'refund',
        accessorFn: receipt => {
          if (receipt.status === ReceiptDTOStatusEnum.Rejected) {
            return 0;
          }
          if ('monthlyRefunds' in receipt) {
            // for recreation, show partial refund of selected month
            return (
              receipt.monthlyRefunds.find(({ month: refundMonth }) => refundMonth === reportFilterState.filter.month.toFormat('yyyy-MM'))
                ?.refund || 0
            );
          }
          return receipt.refund;
        },
        header: t('receipts.refund'),
        enableSorting: false,
        enableGrouping: false,
        Cell: ({ cell }) => {
          const validityMonths = getValidityMonths(
            (cell.row.original as MobilityReceiptTenantDTO).validityStartDate,
            (cell.row.original as MobilityReceiptTenantDTO).validityEndDate,
          );
          return (
            <Stack direction="row" alignItems="center" gap={0.5}>
              <span>{t('common:money', { money: cell.getValue() })}</span>
              {validityMonths && validityMonths > 1 && (
                <Tooltip title={t('receipts.timeCardSplit', { validityMonths })}>
                  <CalendarMonthIcon fontSize="small" />
                </Tooltip>
              )}
            </Stack>
          );
        },
        AggregatedCell: ({ cell }) => <b>{t('common:money', { money: cell.getValue() })}</b>,
      },
      {
        accessorKey: 'status',
        header: t('receipts.status'),
        Cell: ({ row }) => {
          const { status, color } = mapReceiptStatus(row.original);

          return (
            <Typography variant="inherit" component="span" color={color}>
              {t(`receipts.${status.toLowerCase()}`)}
            </Typography>
          );
        },
      },
    ],
    [reportFilterState.filter.month, t],
  );

  const queryResult = useReceiptsQuery(benefit, reportFilterState.filter, mapSortModel(COLUMNS, sorting));
  const loadMoreProps = useLoadMore(queryResult, 'receipts');

  const grouping = useMemo<MRT_GroupingState>(() => {
    if (sorting[0]?.id === 'name' && loadMoreProps.data.length) {
      return ['name'];
    }
    return [];
  }, [loadMoreProps.data.length, sorting]);

  const exportMutation = useExportReceiptsMutation(benefit);

  const handleRowProps = useCallback(
    ({ row }: { row: MRT_Row<LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO> }) => {
      const handleRowClick = () => {
        onSelectReceipt(row.original);
      };
      const handleEnterRowKeyEvent = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          handleRowClick();
        }
      };

      return row.getIsGrouped()
        ? {}
        : {
            onClick: handleRowClick,
            onKeyDown: handleEnterRowKeyEvent,
            sx: { cursor: 'pointer' },
          };
    },
    [onSelectReceipt],
  );
  const handleRowId = useCallback(
    (receipt: LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO) => receipt.id,
    [],
  );
  const rowSelectionState = useMemo(
    () =>
      selectedReceipt
        ? {
            [selectedReceipt.id]: true,
          }
        : {},
    [selectedReceipt],
  );

  const selectedReceiptFromQuery = loadMoreProps.data.find(receipt => receipt.id === selectedReceipt?.id);

  useEffect(() => {
    if (selectedReceiptFromQuery) {
      onReceiptUpdated(selectedReceiptFromQuery);
    }
  }, [onReceiptUpdated, selectedReceiptFromQuery]);

  const renderActions = useCallback(
    ({ row }: { row: MRT_Row<LunchReceiptTenantDTO | MobilityReceiptTenantDTO | RecreationReceiptTenantDTO> }) =>
      row.getIsGrouped() ? null : (
        <Box display="flex" justifyContent="flex-end">
          <ImageSearchIcon color="action" />
        </Box>
      ),
    [],
  );

  return (
    <>
      <Box marginBottom={1.5}>
        <ReportFilterSelector filterState={reportFilterState} exportMutation={exportMutation} />
      </Box>
      <InfiniteScrollReactTable
        {...loadMoreProps}
        columns={COLUMNS}
        getRowId={handleRowId}
        enablePagination={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        enableColumnActions={false}
        enableSortingRemoval={false}
        manualSorting
        initialState={{
          expanded: true, //expand all groups by default
        }}
        onSortingChange={setSorting}
        state={{ sorting, grouping, rowSelection: rowSelectionState }}
        muiTableBodyRowProps={handleRowProps}
        enableRowActions
        positionActionsColumn="last"
        renderRowActions={renderActions}
      />
    </>
  );
};
