import { closestCenter, DndContext, DragEndEvent, DragOverlay, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { DraggableCard, DraggableCardProps } from './DraggableCard';

interface DragAndDropListProps<T> {
  items: DraggableCardProps<T>[];
  onChange: (items: T[]) => void;
}
const DragAndDropList = <T,>({ items, onChange }: DragAndDropListProps<T>) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    const itemId = event.active.id;
    setActiveId(itemId);
  };

  const activeDraggable = useMemo(() => items.find(item => item.id === activeId), [activeId, items]);

  const handleDragCancel = () => {
    setActiveId(null);
  };
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.id === active.id);
      const newIndex = items.findIndex(item => item.id === over.id);
      const newValue = arrayMove(items, oldIndex, newIndex);
      onChange(newValue.map(val => val.data));
    }
    setActiveId(null);
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragCancel={handleDragCancel}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <Stack spacing={1}>
          {items.map((item, index) => (
            <DraggableCard key={index} id={item.id} title={item?.title} icon={item?.icon} data={undefined} />
          ))}
        </Stack>
      </SortableContext>

      <DragOverlay>
        {activeId && activeDraggable ? (
          <DraggableCard id={activeDraggable.id} title={activeDraggable.title} icon={activeDraggable.icon} data={undefined} dragging />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
export default DragAndDropList;
